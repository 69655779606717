/* eslint-disable no-shadow */
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import {
  Card,
  Table,
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import formatAmount from 'utils/formatAmount';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import {
  accountSessionActions,
} from 'store/actions';
import { SpText, SpStatusTag } from 'components/DesignKit';
import swireLogo from 'imgs/swirepay.png';

type Props = {
  accountSession: Array<Object>,
  fetchAccountSession: Function,
  loading: boolean,
  history: {
    push: Function,
  },
  isMobileView: Boolean,
};

const AccountSession = (props: Props) => {
  const {
    accountSession,
    fetchAccountSession,
    loading,
    isMobileView,
  } = props;

  const location = useLocation();
  const { id } = props.match.params;
  // const [pagination, setPagination] = useState({});
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 30,
    },
  });

  const debouncedFetchSessions = debounce(fetchAccountSession, 2000, { leading: true });

  const getData = () => {
    const custGid = id;
    debouncedFetchSessions({
      custGid,
    });
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [location]);

  useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  // useEffect(() => {
  //   const parsed = queryString.parse(location.search);
  //   const currentPage = parsed.pageNum || 1;
  //   setPagination(existingElements => ({
  //     ...existingElements,
  //     total: totalElements,
  //     current: parseInt(currentPage, 10),
  //     showTotal: total => `Total ${total} items`,
  //   }));
  // }, [accountSession]);

  const columns = [
    {
      title: 'ACCOUNT',
      dataIndex: 'name',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const accountName = record?.payerFundingSource?.nickName;
        return (
          <span>
            {accountName || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <span>
          {description || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'name',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {record?.currency?.prefix} {formatAmount((record?.amount / 100), record?.currency)}
        </span>
      ),
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt) {
          const tzDate = moment
            .utc(createdAt)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status) }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
  ];

  // const handleTableChange = (page, sorter, filters) => {
  //   const parsed = queryString.parse(location.search);
  //   const filterParams = parsed.filterParams || '';
  //   const tempPage = page.current;
  //   let tempParams = '';
  //   if (filters.order === 'ascend') {
  //     tempParams = `&sortBy=${filters.field}&direction=ASC`;
  //   } else if (filters.order === 'descend') {
  //     tempParams = `&sortBy=${filters.field}&direction=DESC`;
  //   } else {
  //     tempParams = '';
  //   }
  //   if (location.search && filterParams) {
  //     const filterParam = encodeURIComponent(filterParams);
  //     history.push({
  //       pathname: PAGES.ACCOUNT_SESSIONS,
  //       search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
  //     });
  //   } else {
  //     history.push({
  //       pathname: PAGES.ACCOUNT_SESSIONS,
  //       search: `?pageNum=${tempPage}${tempParams}`,
  //     });
  //   }
  // };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <div style={{ marginBottom: '50px' }}>&nbsp;</div>
      <Row
        className="my-2 d-flex justify-content-end"
        style={{
          background: '#B7C9E5', height: '4px',
        }}
      >
        <Col>
          <img
            src={swireLogo}
            alt=""
            style={{
              width: '220px', position: 'absolute', top: '-50px', left: '-225px', background: 'white',
            }}
          />
        </Col>
      </Row>
      <div style={{ marginTop: '25px' }}>&nbsp;</div>
      <Row className="my-2">
        <Col>
          <SpText fontSize="22px" fontWeight="600">Payment details</SpText>
        </Col>
      </Row>
      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={accountSession}
        pagination={tableParams.pagination}
        // pagination={pagination}
        // onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  accountSession: state.accountSession.content ? state.accountSession.content : [],
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountSession: gid => dispatch(accountSessionActions.fetchAccountSession({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSession);
