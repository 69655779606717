import React, { useEffect, useState, useRef } from 'react';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { NOTIFICATION } from 'store/actionTypes';
import {
  Input,
  Row,
  Col,
  Tabs,
  Checkbox,
  Select,
  DatePicker,
  AutoComplete,
} from 'antd';
import {
  manageAccountActions,
  fundingSourceActions,
  transferSettingsActions,
  notificationActions,
  transfersActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  SpError,
  SpText,
  SpButton,
  SpH5,
  SpForm,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
  CURRENCY,
} from 'appconstants';
import Lock from '../../assets/imgs/lock.svg';

const { TabPane } = Tabs;
const { Option } = Select;

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
  fetchSelfAccounts: Function,
  selectedAccount: Object,
  test: boolean,
  manageAccountsList: Array<Object>,
  fetchFundingSourceList: Function,
  fundingSource: Array<Object>,
  fetchTransferSetting: Function,
  transferSettings: Object,
  setNotification: Function,
  confirmTransferOtp: Function,
  issueTransfer: Function,
  issuerGid: Object,
  fetchTransfers: Function,
  validator: Boolean,
  getContactList: Function,
  contactList: Array,
  loading: Boolean,
};

const SendMoney = (props: Props) => {
  const {
    // history,
    fetchSelfAccounts,
    selectedAccount,
    test,
    manageAccountsList,
    fetchFundingSourceList,
    fundingSource,
    fetchTransferSetting,
    transferSettings,
    setNotification,
    confirmTransferOtp,
    issueTransfer,
    issuerGid,
    fetchTransfers,
    validator,
    getContactList,
    contactList,
    loading,
  } = props;
  const [manageAccountInfo, setManageAccountInfo] = useState(manageAccountsList);
  const [manageContactInfo, setManageContactInfo] = useState(fundingSource);
  const [manageAccountInfoSelf, setManageAccountInfoSelf] = useState(manageAccountsList);
  const [manageContactInfoSelf, setManageContactInfoSelf] = useState(manageAccountsList);
  const location = useLocation();
  const [, forceUpdate] = useState();
  // eslint-disable-next-line
  const [transfers, setTransfers] = useState(1);
  const [error, setError] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactGid, setContactGid] = useState('');
  const [benficiaryName, setBenficiaryName] = useState('');
  const [benficiaryGid, setBenficiaryGid] = useState('');
  const [sendMoneyStep, setSendMoneyStep] = useState(0);
  const [transferAmount, setTransferAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [transferApiDate, setTransferApiDate] = useState();
  const [transferMode, setTransferMode] = useState('RTP');
  const [remarks, setRemarks] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [UniqueId, setUniqueId] = useState('');
  const [otp, setOtp] = useState();
  const [accontTransferStep, setAccountTransferStep] = useState(0);
  const debouncedFetchSelfAccounts = debounce(fetchSelfAccounts, 2000, { leading: true });
  const debouncedFetchFundingSourceList = debounce(fetchFundingSourceList, 2000, { leading: true });
  const debouncedFetchTransfers = debounce(fetchTransfers, 2000, { leading: true });
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const disableDate = transferDate;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const supportsSchedule = (selectedAccount.issuer && selectedAccount.issuer.supportsSchedule);
  const { id } = selectedAccount && selectedAccount.currency;
  const minLimit = (transferSettings && transferSettings.minLimit / 100);
  const maxLimit = (transferSettings && transferSettings.maxLimit / 100);
  const [otpConfirm, setOtpConfirm] = useState(false);

  const [contactNameSelf, setContactNameSelf] = useState('');
  const [contactGidSelf, setContactGidSelf] = useState('');
  const [benficiaryContactGid, setBenficiaryContactGid] = useState('');
  const [benficiaryNameSelf, setBenficiaryNameSelf] = useState('');
  const [benficiaryGidSelf, setBenficiaryGidSelf] = useState('');
  const [transferAmountSelf, setTransferAmountSelf] = useState();
  const [transferDateSelf, setTransferDateSelf] = useState();
  const [transferApiDateSelf, setTransferApiDateSelf] = useState();
  const [transferModeSelf, setTransferModeSelf] = useState('RTP');
  const [remarksSelf, setRemarksSelf] = useState('');
  // eslint-disable-next-line
  const [acceptTermsSelf, setAcceptTermsSelf] = useState(false);
  const [UniqueIdSelf, setUniqueIdSelf] = useState('');
  const [otpSelf, setOtpSelf] = useState();
  const [otpConfirmSelf, setOtpConfirmSelf] = useState(false);
  const disableDateSelf = transferDateSelf;

  useEffect(() => {
    if (manageAccountsList && manageAccountsList.length > 0) {
      setManageAccountInfo(manageAccountsList);
      setManageAccountInfoSelf(manageAccountsList);
      setManageContactInfoSelf(manageAccountsList);
    }
  }, [manageAccountsList]);

  useEffect(() => {
    if (fundingSource && fundingSource.length > 0) {
      setManageContactInfo(fundingSource);
    }
  }, [fundingSource]);

  useEffect(() => {
    const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
      .format(dateFormat);
    // eslint-disable-next-line
    const selectedDate = moment.utc().tz(selectedAccount.timezone).format(MOMENT_FORMAT);
    if (selectedAccount && selectedAccount.timezone) {
      setTransferDate(displayFormat);
      setTransferApiDate(selectedDate);
      setTransferDateSelf(displayFormat);
      setTransferApiDateSelf(selectedDate);
    }
  }, []);


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    debouncedFetchSelfAccounts({ currentPage, sortParams, filterParam });
    debouncedFetchFundingSourceList({ currentPage, sortParams, filterParam });
    fetchTransferSetting({ sortParams });
    debouncedFetchTransfers({
      currentPage,
      sortParams,
      filterParam,
    });
    getContactList();
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      imps: {
        message: MAX_AMOUNT.MESSAGE_IMPS,
        rule: (val) => (
          MAX_AMOUNT.IMPS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.IMPS_MAX_AMOUNT),
      },
      rtgs: {
        message: MAX_AMOUNT.MESSAGE_RTGS,
        rule: (val) => (
          MAX_AMOUNT.RTGS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.RTGS_MAX_AMOUNT),
      },
      neft: {
        message: MAX_AMOUNT.MESSAGE_NEFT,
        rule: (val) => (
          MAX_AMOUNT.NEFT_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.NEFT_MAX_AMOUNT),
      },
      minmaxAmount: {
        rule: (val, params) => {
          if (params && params[0] && val) {
            return Number(params[0].split('&')[0]) <= Number(val)
            && Number(val) <= Number(params[0].split('&')[1]);
          }
          return false;
        },
      },
    },
  }));
  simpleValidator.current.purgeFields();

  useEffect(() => {
    simpleValidator.current.purgeFields();
    if (!validator && otpConfirm) {
      setSendMoneyStep(3);
      setContactName('');
      setContactGid('');
      setBenficiaryName('');
      setBenficiaryGid('');
      setTransferAmount();
      setTransferDate();
      setTransferApiDate();
      setTransferMode('RTP');
      setRemarks('');
      setAcceptTerms(false);
      setUniqueId('');
      setOtp();
    }
    if (!validator && otpConfirmSelf) {
      setAccountTransferStep(3);
      setContactNameSelf('');
      setContactGidSelf('');
      setBenficiaryNameSelf('');
      setBenficiaryGidSelf('');
      setTransferAmountSelf();
      setTransferDateSelf();
      setTransferApiDateSelf();
      setTransferModeSelf('RTP');
      setRemarksSelf('');
      setAcceptTermsSelf(false);
      setUniqueIdSelf('');
      setOtpSelf();
      setError(false);
    }
  }, [validator]);

  const sendMoney = (
    <div style={{ textAlign: 'left', padding: '8px 8px 8px 0px' }}>
      <span style={{ fontSize: '18px', fontWeight: '600' }}>Send Money</span>
      <div>
        <span style={{ fontSize: '12px', fontWeight: '400' }}>Transfer funds to third parties</span>
      </div>
    </div>
  );

  const accountTransfer = (
    <div style={{ textAlign: 'left', padding: '8px 8px 8px 0px' }}>
      <span style={{ fontSize: '18px', fontWeight: '600' }}>Account Transfers</span>
      <div>
        <span style={{ fontSize: '12px', fontWeight: '400' }}>Between your accounts</span>
      </div>
    </div>
  );

  const updateContactSelf = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setContactNameSelf(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setContactGidSelf(results[0].gid);
    }
  };

  const updateBenficiarySelf = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setBenficiaryNameSelf(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setBenficiaryGidSelf(results[0].contact.gid);
    }
  };

  const updateContact = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setContactName(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setContactGid(results[0].gid);
    }
  };

  const updateBenficiary = (value) => {
    const results = fundingSource.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setBenficiaryName(results[0].contact.name);
      setBenficiaryGid(results[0].gid);
      setBenficiaryContactGid(results[0].contact.gid);
    }
  };

  const onDateChangeSelf = (date, dateString) => {
    setTransferDateSelf(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setTransferApiDateSelf(Date);
  };

  const onDateChange = (date, dateString) => {
    setTransferDate(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setTransferApiDate(Date);
  };

  const addNewTransferSelf = () => {
    const amount = transferAmountSelf.replace(/[^\d]/g, '');
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(amount) > approveLimt) && !selectedAccount.issuer.requiresOtp) {
      setAccountTransferStep(accontTransferStep + 1);
    } else {
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryGidSelf,
        fundingSourceGid: contactGidSelf,
        description: remarksSelf,
        transferTypeCode: transferModeSelf,
        scheduleDate: supportsSchedule ? transferApiDateSelf : null,
        confirmationEmail: transferSettings.approvalEmail,
        confirmationSms: selectedAccount.contactNumber,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
      });
      setOtpConfirmSelf(true);
    }
  };

  const otpTransferSelf = async () => {
    const params = { otpCode: otpSelf };
    if (selectedAccount.issuer.requiresOtp && !test) {
      confirmTransferOtp({
        gid: issuerGid.gid,
        params,
      });
      setOtpConfirmSelf(true);
    } else {
      setOtpConfirmSelf(true);
      const amount = transferAmountSelf.replace(/[^\d]/g, '');
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryGidSelf,
        fundingSourceGid: contactGidSelf,
        description: remarksSelf,
        transferTypeCode: transferModeSelf,
        scheduleDate: supportsSchedule ? transferApiDateSelf : null,
        confirmationEmail: transferSettings.approvalEmail,
        confirmationSms: selectedAccount.contactNumber,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        swirepayOtpGid: UniqueIdSelf,
        otpCode: otpSelf,
      });
    }
  };

  const addNewTransfer = () => {
    const amount = transferAmount.replace(/[^\d]/g, '');
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(amount) > approveLimt) && !selectedAccount.issuer.requiresOtp) {
      setSendMoneyStep(sendMoneyStep + 1);
      simpleValidator.current.purgeFields();
    } else {
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryContactGid,
        payerFundingSourceGid: contactGid,
        fundingSourceGid: benficiaryGid,
        description: remarks,
        transferTypeCode: transferMode,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail: transferSettings.approvalEmail,
        confirmationSms: selectedAccount.contactNumber,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
      });
      setOtpConfirm(true);
    }
  };

  const otpTransfer = async () => {
    const params = { otpCode: otp };
    if (selectedAccount.issuer.requiresOtp && !test) {
      confirmTransferOtp({
        gid: issuerGid.gid,
        params,
      });
      setOtpConfirm(true);
    } else {
      setOtpConfirm(true);
      simpleValidator.current.purgeFields();
      const amount = transferAmount.replace(/[^\d]/g, '');
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryContactGid,
        payerFundingSourceGid: contactGid,
        fundingSourceGid: benficiaryGid,
        description: remarks,
        transferTypeCode: transferMode,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail: transferSettings.approvalEmail,
        confirmationSms: selectedAccount.contactNumber,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        swirepayOtpGid: UniqueId,
        otpCode: otp,
      });
    }
  };

  const getOtpSelf = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueIdSelf(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  const getOtp = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueId(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  const transferFunds = (event) => {
    if (sendMoneyStep === 0) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setSendMoneyStep(sendMoneyStep + 1);
    } else if (sendMoneyStep === 1) {
      if (!selectedAccount.issuer.requiresOtp) {
        getOtp();
      }
      addNewTransfer();
    } else if (sendMoneyStep === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      otpTransfer();
    }
  };

  const transferFundsSelf = () => {
    if (accontTransferStep === 0) {
      if (contactNameSelf && benficiaryNameSelf && transferAmountSelf && transferDateSelf && remarksSelf) {
        setAccountTransferStep(accontTransferStep + 1);
      } else {
        setError(true);
      }
    } else if (accontTransferStep === 1) {
      if (!selectedAccount.issuer.requiresOtp) {
        getOtpSelf();
      }
      addNewTransferSelf();
    } else if (accontTransferStep === 2) {
      if (otpSelf) {
        otpTransferSelf();
      } else {
        setError(true);
      }
    }
  };

  const onChange = (key) => {
    setTransfers(key);
  };

  const searchContact = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageAccountInfo(testArray);
    } else {
      setManageAccountInfo(manageAccountsList);
    }
  };

  const searchContactList = (val) => {
    if (val) {
      const testArray = fundingSource.filter((item) => (item.contact.name.toLowerCase()).includes(val.toLowerCase()));
      setManageContactInfo(testArray);
    } else {
      setManageContactInfo(fundingSource);
    }
  };

  const searchContactSelf = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageAccountInfoSelf(testArray);
    } else {
      setManageAccountInfoSelf(manageAccountsList);
    }
  };

  const searchContactListSelf = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageContactInfoSelf(testArray);
    } else {
      setManageContactInfoSelf(manageAccountsList);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="mx-auto mt-5 py-3 px-3"
      style={{ width: 800, height: 'auto' }}
      bodyStyle={{ height: '100%' }}
    >
      <Tabs defaultActiveKey="1" tabPosition="top" onChange={onChange}>
        <TabPane tab={sendMoney} key="1" style={{ backgroundColor: '#FFF', padding: '15px', marginTop: '-15px' }}>
          <>
            {
            sendMoneyStep === 0 && (
              <>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>From</SpH5></label>
                  <AutoComplete
                    placeholder="Select Account"
                    showSearch
                    className="w-100"
                    onSearch={searchContact}
                    dataSource={manageAccountInfo.map((item, key) => (
                      <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {`${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`}
                        {item.contact.primary && <span className="text-primary">Primary</span>}
                      </Option>
                    ))}
                    style={{ height: '40px' }}
                    defaultValue={contactName}
                    onSelect={e => updateContact(e)}
                  />
                  <SpError>
                    {simpleValidator.current.message('account', contactName, 'required')}
                  </SpError>

                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>To</SpH5></label>
                  <AutoComplete
                    placeholder="Select Contact"
                    showSearch
                    className="w-100"
                    onSearch={searchContactList}
                    dataSource={manageContactInfo.map((item, key) => (
                      <Option key={key} value={item.gid}>
                        {item.contact.name} | {`${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`}
                      </Option>
                    ))}
                    style={{ height: '40px' }}
                    defaultValue={benficiaryName}
                    onSelect={e => updateBenficiary(e)}
                  />
                  <SpError>
                    {simpleValidator.current.message('contact', benficiaryName, 'required')}
                  </SpError>

                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>Amount</SpH5></label>
                  <Input
                    value={transferAmount}
                    prefix={prefix}
                    placeholder="0.00"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setTransferAmount(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setTransferAmount(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setTransferAmount(value);
                      }
                    }}
                  />
                  {
                      transferSettings && Object.keys(transferSettings).length ? (
                        <SpError>
                          {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                        </SpError>
                        // <SpError>
                        //   {simpleValidator.current.message('amount',
                        //     transferAmount, `required|minmaxAmount:${minLimit}&${maxLimit}`,
                        //     { messages: { required: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}` } })}
                        // </SpError>
                      ) : (
                        <SpError>
                          {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                        </SpError>
                      )
                    }

                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>Transfer mode</SpH5></label>
                  <div className="d-flex">
                    <div className="mr-3" style={{ width: '33%' }}>
                      <SpButton
                        type={transferMode === 'RTP' ? 'primary' : 'secondary'}
                        style={{ width: '100%' }}
                        onClick={() => setTransferMode('RTP')}
                      >
                        RTP
                      </SpButton>
                    </div>
                    <div className="mr-3" style={{ width: '33%' }}>
                      <SpButton
                        type={transferMode === 'ACH' ? 'primary' : 'secondary'}
                        style={{ width: '100%' }}
                        onClick={() => setTransferMode('ACH')}
                      >
                        ACH
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton type="secondary" style={{ width: '100%' }} disabled>eCheck</SpButton>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="d-flex">
                    <div className="w-50 mr-3">
                      <label className="d-block"><SpH5>Date</SpH5></label>
                      <div>
                        <DatePicker
                          value={moment(transferDate)}
                          format={dateFormat}
                          allowClear={false}
                          onChange={onDateChange}
                          disabledDate={current => current && current < moment().subtract(1, 'days')}
                        />
                        <SpError>
                          {simpleValidator.current.message('Transfer Date', transferDate, 'required')}
                        </SpError>
                      </div>
                    </div>
                    <div className="w-50">
                      <label className="d-block"><SpH5>Remarks</SpH5></label>
                      <Input
                        placeholder="Remarks"
                        onChange={(e) => setRemarks(e.currentTarget.value)}
                        value={remarks}
                      />
                      <SpError>
                        {simpleValidator.current.message('remarks', remarks, 'required')}
                      </SpError>

                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="primary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={(e) => transferFunds(e)}
                    >
                      Continue
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
              </>
            )
          }
            {
            sendMoneyStep === 1 && (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>From</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{contactName}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>To</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{benficiaryName}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Amount</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferAmount}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Transfer Mode</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferMode}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Date</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferDate}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Remarks</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{remarks}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <SpH5><Checkbox
                        className="mr-2"
                        defaultChecked={acceptTerms}
                        onChange={({ target: { checked } }) => {
                          setAcceptTerms(checked);
                        }}
                      /> I accept the <span style={{ color: '#2B5EAD' }}>Terms and Conditions</span>
                      </SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <div className="d-flex mb-3">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="primary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={(e) => transferFunds(e)}
                    >
                      Confirm
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="secondary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={() => setSendMoneyStep(0)}
                    >
                      Back
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
              </>
            )
          }
            {
              sendMoneyStep === 2 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <img src={Lock} alt="Lock" />
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">Enter code</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                      </Col>
                    </Row>
                  </SpForm>

                  {/* {
                    selectedAccount.issuer.requiresOtp ? (
                      <SpForm>
                        <Row type="flex" justify="center" align="middle">
                          <Col span={12}>
                            <SpText fontSize="18px">Enter the code sent to {phoneCode} ******{lastFour}</SpText>
                          </Col>
                        </Row>
                      </SpForm>
                    ) : (!selectedAccount.issuer.requiresOtp && (transferSettings && transferSettings.approvalEmail)) ? (
                      <SpForm>
                        <Row type="flex" justify="center" align="middle">
                          <Col span={12}>
                            <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                          </Col>
                        </Row>
                      </SpForm>
                    ) : ''
                  } */}
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <div style={{ marginLeft: '25%' }}>
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span style={{ width: '8px' }} />}
                            inputType="password"
                            inputStyle={{
                              border: '1px solid',
                              borderRadius: '8px',
                              width: '54px',
                              height: '54px',
                              fontSize: '12px',
                              color: '#000',
                              fontWeight: '400',
                              caretColor: 'blue',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>

                        {/* <Input
                          type="password"
                          style={{ width: '50%' }}
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.currentTarget.value)}
                        /> */}
                        <SpError>
                          {simpleValidator.current.message('otp', otp, 'required')}
                        </SpError>

                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFunds(e)}
                      >
                        Verify
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              sendMoneyStep === 3 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">You have successfully completed the transfer</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setSendMoneyStep(0)}
                      >
                        Make another transaction
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setSendMoneyStep(0)}
                      >
                        Home
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
          </>
        </TabPane>
        <TabPane tab={accountTransfer} key="2" style={{ backgroundColor: '#FFF', padding: '15px', marginTop: '-15px' }}>
          <>
            {
            accontTransferStep === 0 && (
              <>
                <div className="mb-3">
                  {
                    error && (
                      <div>
                        <SpError>
                          Please provide the required information
                        </SpError>
                      </div>
                    )
                  }
                  <label htmlFor=""><SpH5>From</SpH5></label>
                  <AutoComplete
                    placeholder="Select Account"
                    showSearch
                    className="w-100"
                    onSearch={searchContactSelf}
                    dataSource={manageAccountInfoSelf.map((item, key) => (
                      <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {`${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`}
                        {item.contact.primary && <span className="text-primary">Primary</span>}
                      </Option>
                    ))}
                    style={{ height: '40px' }}
                    defaultValue={contactNameSelf}
                    onSelect={e => updateContactSelf(e)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>To</SpH5></label>
                  <AutoComplete
                    placeholder="Select Account"
                    showSearch
                    className="w-100"
                    onSearch={searchContactListSelf}
                    dataSource={manageContactInfoSelf.map((item, key) => (
                      <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {`${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`}
                        {item.contact.primary && <span className="text-primary">Primary</span>}
                      </Option>
                    ))}
                    style={{ height: '40px' }}
                    defaultValue={benficiaryNameSelf}
                    onSelect={e => updateBenficiarySelf(e)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>Amount</SpH5></label>
                  <Input
                    value={transferAmountSelf}
                    prefix={prefix}
                    placeholder="0.00"
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/;
                      const { value } = e.currentTarget;
                      if (regex.test(value) || value === '') {
                        setTransferAmountSelf(e.currentTarget.value);
                      } else if (!regex.test(value)) {
                        setTransferAmountSelf(0.00);
                      }
                    }}
                    onKeyPress={e => {
                      const keyCode = e.charCode || e.which;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      if (e.currentTarget.value) {
                        const value = formatAmount(e.currentTarget.value, currencyType);
                        setTransferAmountSelf(value);
                      }
                    }}
                  />
                  {/* {
                      transferSettings && Object.keys(transferSettings).length ? (
                        <SpError>
                          {simpleValidator.current.message('amount',
                            transferAmountSelf, `required|minmaxAmount:${minLimit}&${maxLimit}`,
                            { messages: { required: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}` } })}
                        </SpError>
                      ) : (
                        <SpError>
                          {simpleValidator.current.message('amount', transferAmountSelf, 'required|amount')}
                        </SpError>
                      )
                    } */}

                </div>
                <div className="mb-3">
                  <label htmlFor=""><SpH5>Transfer mode</SpH5></label>
                  <div className="d-flex">
                    <div className="mr-3" style={{ width: '33%' }}>
                      <SpButton
                        type={transferModeSelf === 'RTP' ? 'primary' : 'secondary'}
                        style={{ width: '100%' }}
                        onClick={() => setTransferModeSelf('RTP')}
                      >
                        RTP
                      </SpButton>
                    </div>
                    <div className="mr-3" style={{ width: '33%' }}>
                      <SpButton
                        type={transferModeSelf === 'ACH' ? 'primary' : 'secondary'}
                        style={{ width: '100%' }}
                        onClick={() => setTransferModeSelf('ACH')}
                      >
                        ACH
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton type="secondary" style={{ width: '100%' }} disabled>eCheck</SpButton>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="d-flex">
                    <div className="w-50 mr-3">
                      <label className="d-block"><SpH5>Date</SpH5></label>
                      <div>
                        <DatePicker
                          value={moment(transferDateSelf)}
                          format={dateFormat}
                          allowClear={false}
                          onChange={onDateChangeSelf}
                          disabledDate={current => current && current < moment().subtract(1, 'days')}
                        />
                        {/* <SpError>
                          {simpleValidator.current.message('Transfer Date', transferDateSelf, 'required')}
                        </SpError> */}

                      </div>
                    </div>
                    <div className="w-50">
                      <label className="d-block"><SpH5>Remarks</SpH5></label>
                      <Input
                        placeholder="Remarks"
                        onChange={(e) => setRemarksSelf(e.currentTarget.value)}
                        value={remarksSelf}
                      />
                      {/* <SpError>
                          {simpleValidator.current.message('remarks', remarksSelf, 'required')}
                        </SpError> */}

                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="primary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={(e) => transferFundsSelf(e)}
                    >
                      Continue
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
              </>
            )
          }
            {
            accontTransferStep === 1 && (
              <>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>From</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{contactNameSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>To</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{benficiaryNameSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Amount</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferAmountSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Transfer Mode</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferModeSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Date</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{transferDateSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={6}>
                      <SpH5>Remarks</SpH5>
                    </Col>
                    <Col span={6}>
                      <SpH5>{remarksSelf}</SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <SpH5><Checkbox
                        className="mr-2"
                        defaultChecked={acceptTerms}
                        onChange={({ target: { checked } }) => {
                          setAcceptTerms(checked);
                        }}
                      /> I accept the <span style={{ color: '#2B5EAD' }}>Terms and Conditions</span>
                      </SpH5>
                    </Col>
                  </Row>
                </SpForm>
                <div className="d-flex mb-3">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="primary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={(e) => transferFundsSelf(e)}
                    >
                      Confirm
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                  <div style={{ width: '33%' }}>
                    <SpButton
                      type="secondary"
                      style={{ width: '100%', fontSize: '18px', height: '40px' }}
                      onClick={() => setAccountTransferStep(0)}
                    >
                      Back
                    </SpButton>
                  </div>
                  <div style={{ width: '33%' }}>
                &nbsp;
                  </div>
                </div>
              </>
            )
          }
            {
              accontTransferStep === 2 && (
                <>
                  {
                    error && (
                      <div>
                        <SpError>
                          Plese provide the OTP
                        </SpError>
                      </div>
                    )
                  }
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <img src={Lock} alt="Lock" />
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">Enter code</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <div style={{ marginLeft: '25%' }}>
                          <OtpInput
                            value={otpSelf}
                            onChange={setOtpSelf}
                            numInputs={6}
                            renderSeparator={<span style={{ width: '8px' }} />}
                            inputType="password"
                            inputStyle={{
                              border: '1px solid',
                              borderRadius: '8px',
                              width: '54px',
                              height: '54px',
                              fontSize: '12px',
                              color: '#000',
                              fontWeight: '400',
                              caretColor: 'blue',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFundsSelf(e)}
                      >
                        Verify
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              accontTransferStep === 3 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">You have successfully completed the transfer</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setAccountTransferStep(0)}
                      >
                        Make another transaction
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setAccountTransferStep(0)}
                      >
                        Home
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
          </>
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  manageAccountsList: state.manageAccounts.content,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  fundingSource: state.fundingSource.content,
  transferSettings: state.transferSettings.content,
  issuerGid: state.transfers.issuerGid,
  contactList: state.transfers.contactList,
  loading: state.loading.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelfAccounts: param => dispatch(manageAccountActions.fetchSelfAccounts({
    payload: param,
  })),
  fetchFundingSourceList: param => dispatch(fundingSourceActions.fetchFundingSourceList({
    payload: param,
  })),
  fetchTransferSetting: gid => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: gid,
  })),
  issueTransfer: param => dispatch(transfersActions.issueTransfer({
    payload: param,
  })),
  confirmTransferOtp: param => dispatch(transfersActions.confirmTransferOtp({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchTransfers: param => dispatch(transfersActions.fetchTransfers({
    payload: param,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendMoney);
