import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Tooltip,
  Row,
  Col,
  Radio,
  Icon,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import Loading from 'components/Loading';

import {
  manageAccountActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  COUNTRIES,
  BENE_TYPES,
} from 'appconstants';
import {
  SpButton,
} from 'components/DesignKit';
import AddAccont from './components/AddAccont';
import DeleteAccount from './components/DeleteAccount';


type Props = {
  fetchSelfAccounts: Function,
  manageAccountsList: Array<Object>,
  loading: boolean,
  test: boolean,
  history: {
    push: Function,
  };
  totalElements: number,
  selectedAccount: Object,
  deleteSelefAccount: Function,
  submitting: Boolean,
  addSelfAccount: Function,
  updateSelfAccount: Function,
};

const ManageAccounts = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    fetchSelfAccounts,
    manageAccountsList,
    test,
    history,
    totalElements,
    selectedAccount,
    deleteSelefAccount,
    submitting,
    addSelfAccount,
    updateSelfAccount,
  } = props;
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [addAccontModal, setAddAccountModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecordAccount, setSelectedRecordAccount] = useState();
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const debouncedFetchSelfAccounts = debounce(fetchSelfAccounts, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    debouncedFetchSelfAccounts({ currentPage, sortParams, filterParam });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.MANAGE_ACCOUNTS}/${searchParams}`,
      state: location.search,
    });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!submitting) {
      setShowDeleteModal(false);
      setAddAccountModal(false);
    }
  }, [submitting]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [manageAccountsList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const searchParam = parsed['status.IN'];
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && searchParam && !filterParams) {
      history.push({
        pathname: PAGES.MANAGE_ACCOUNTS,
        search: `?status.IN=${searchParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && !filterParams) {
      history.push({
        pathname: PAGES.MANAGE_ACCOUNTS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    } else if (searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.MANAGE_ACCOUNTS,
        search: `?status.IN=${searchParam}&filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else if (!searchParam && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.MANAGE_ACCOUNTS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const setDeleteAccount = (record) => {
    setSelectedRecordAccount(record);
    setShowDeleteModal(true);
  };

  const setDefaultContact = (record) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const payload = { currentPage, sortParams, filterParam };
    const contactGid = record.contact.gid;
    updateSelfAccount({ payload, contactGid });
  };

  const columns = [
    {
      title: 'NICK NAME',
      dataIndex: 'nickName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (nickName) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{nickName || <>&#8211;</>}</span>
        </Tooltip>
      ),
    },
    {
      title: 'BANK NAME',
      dataIndex: 'bankName',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{record.issuerBank.bankName}</span>
        </Tooltip>
      ),
    },
    {
      title: 'ACCOUNT NO.',
      dataIndex: 'issuerBank',
      width: '15%',
      align: 'left',
      render: (issuerBank, record) => {
        const bankName = ((record.issuerBank) && (record.issuerBank.bankName));
        const lastFour = ((record.issuerBank) && (record.issuerBank.lastFour));
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`${bankName} ****${lastFour}`}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {`****${lastFour}`}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'ROUTING NUMBER',
      dataIndex: 'issuerBank',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>{record.issuerBank.routingNumber}</span>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'createdAt',
      width: '13%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span>{tzDate}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>&#8211;</span>
          </Tooltip>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'PRIMARY A/C',
      dataIndex: 'primary',
      width: '10%',
      align: 'center',
      render: (text, record) => (
        <Radio
          onClick={(e) => { e.stopPropagation(); setDefaultContact(record); }}
          checked={record.contact.primary}
        />
      ),
    },
    {
      title: 'ACTION',
      dataIndex: 'delete',
      width: '13%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); setDeleteAccount(record); }}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const addNewBankAccount = (transferInfo) => {
    const {
      contactGid,
      contactAddGid,
      accountNumber,
      routingNumber,
      accountType,
      bankName,
      upiId,
      transferType,
      businessType,
      accountHolderName,
      nickName,
    } = transferInfo;

    let issuerBank;
    if (transferType === BENE_TYPES.UPI) {
      issuerBank = {
        beneType: BENE_TYPES.VPA,
        vpa: upiId,
        countryCode: selectedAccount.country.alpha2,
      };
    } else {
      issuerBank = {
        accountNumber,
        routingNumber,
        accountType,
        businessType,
        bankName,
        beneType: BENE_TYPES.BANK,
        countryCode: selectedAccount.country.alpha2,
        accountHolderName,
      };
    }
    const data = {
      contactGid: contactAddGid || contactGid,
      issuerBank,
      nickName,
    };
    const currentPage = 1;
    const sortBy = 'createdAt';
    const direction = 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = '';
    const payload = { currentPage, sortParams, filterParam };
    addSelfAccount({ payload, data });
  };

  const deleteSelectedAccount = (data) => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const payload = { currentPage, sortParams, filterParam };
    deleteSelefAccount({ payload, data });
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <SpButton
              type="secondary"
              shape="round"
              className="mr-3"
              ghost
              onClick={() => setAddAccountModal(true)}
            >
              {'\u002B'}&nbsp;Add New Account
            </SpButton>
          </Col>
        </div>
      </Row>
      <AddAccont
        close={() => setAddAccountModal(false)}
        submit={addNewBankAccount}
        visible={addAccontModal}
        selectedAccount={selectedAccount}
        country={selectedAccount.country.name}
        cntryId={selectedAccount.country.id}
        isIndia={isIndia}
      />

      <DeleteAccount
        close={() => setShowDeleteModal(false)}
        submit={deleteSelectedAccount}
        visible={showDeleteModal}
        selectedRecordAccount={selectedRecordAccount}
      />

      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        dataSource={manageAccountsList}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  manageAccountsList: state.manageAccounts.content,
  loading: state.loading.loading,
  totalElements: state.manageAccounts.totalElements,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelfAccounts: param => dispatch(manageAccountActions.fetchSelfAccounts({
    payload: param,
  })),
  updateSelfAccount: param => dispatch(manageAccountActions.updateSelfAccount({
    payload: param,
  })),
  deleteSelefAccount: param => dispatch(manageAccountActions.deleteSelefAccount({
    payload: param,
  })),
  addSelfAccount: param => dispatch(manageAccountActions.addSelfAccount({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccounts);
