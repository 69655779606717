// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Select,
  DatePicker,
} from 'antd';
import {
  SpText,
  SpError,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  inventoryReservationsActions,
  notificationActions,
  customerSupportActions,
  diningActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import getReservation from 'utils/getReservation';
import getReservationBackground from 'utils/getReservationBackground';
import reservationCancel from 'imgs/rescancel.png';
import Reset from 'imgs/Restart.png';
import DeletePrinterLabel from './components/DeleteOrderType';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const kdsRole = localStorage.getItem('kdsRole');

type Props = {
  inventoryReservations: Array<Object>,
  fetchInventoryReservations: Function,
  deleteInventoryReservations: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  submitting: Boolean,
  validator: Boolean,
  fetchShops: Function,
  shops: Array<objects>,
  fetchFilterReservations: Function,
  fetchDiningTable: Function,
  diningTable: Array,
  fetchSearchReservations: Function,
  searchTypes: String,
  isMobileView: Boolean,
};

// $FlowFixMe
const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const ReservationsList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryReservations,
    loading,
    fetchInventoryReservations,
    deleteInventoryReservations,
    totalElements,
    test,
    history,
    submitting,
    validator,
    fetchShops,
    shops,
    fetchFilterReservations,
    fetchDiningTable,
    diningTable,
    fetchSearchReservations,
    searchTypes,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [pagination, setPagination] = useState({});
  const [deletePrinterLabelModal, setDeletePrinterLabelModal] = useState(false);
  const [selectedPrinterLabel, setSelectedPrinterLabel] = useState({});
  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDateWithTimezone, setFromDateWithTimezone] = useState('');
  const [toDateWithTimeZone, setToDateWithTimeZone] = useState('');
  const todayDate = moment.utc(new Date()).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
  const disableDate = moment().endOf('day');
  const [showMessage, setShowMessage] = useState(false);
  const [selectedStatusType, setSelectedStatusType] = useState({});
  const [tableName, setTableName] = useState('');
  const statusTypes = [
    {
      id: 1,
      name: 'Reserved',
      value: 'RESERVED',
    },
    {
      id: 2,
      name: 'Occupied',
      value: 'OCCUPIED',
    },
    {
      id: 3,
      name: 'Closed',
      value: 'CLOSED',
    },
    {
      id: 4,
      name: 'Abandoned',
      value: 'ABANDONED',
    },
    {
      id: 5,
      name: 'Cancelled',
      value: 'CANCELED',
    },
    {
      id: 6,
      name: 'All',
      value: 'ALL',
    },
  ];

  const debouncedFetchShops = debounce(fetchShops, 2000, { leading: true });
  const debouncedFetchDiningTable = debounce(fetchDiningTable, 2000, { leading: true });

  useEffect(() => {
    if (shops && shops.length !== 0 && !searchTypes) {
      setShopsList(shops);
      // setSelectedShop(shops[0]);
      // setSelectedShop('All');
      const payload = {
        shopGid: shops[0].gid,
      };
      debouncedFetchDiningTable(payload);
    }
  }, [shops]);

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    debouncedFetchShops({ currentPage, sortParams, filterParams });
  };

  useEffect(() => {
    getData(location.search);
  }, [selectedAccount, test]);

  useEffect(() => {
    if (searchTypes) {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: searchTypes,
      });
      fetchSearchReservations('');
    }
  }, [searchTypes]);

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const dateStart = parsed.fromDate || '';
      const dateEnd = parsed.toDate || '';
      const status = parsed.status || '';
      const tablenum = parsed.shopTableName || '';
      // Date with TimeZone Might change
      // setFromDate(dateStart);
      // setToDate(dateEnd);
      setTableName(tablenum);
      const typeStatus = statusTypes.filter((item) => item.value === status);
      if (typeStatus && typeStatus.length !== 0) {
        setSelectedStatusType(typeStatus[0]);
      }
      const shopSelcted = shops.filter((item) => item.name === shopName);
      if (shopSelcted && shopSelcted.length !== 0) {
        setSelectedShop(shopSelcted[0]);
      }
      let filterParams;
      if (dateStart && dateEnd) {
        // eslint-disable-next-line max-len
        filterParams = `?status.EQ=${status}&shopTableName.EQ=${(tablenum)}&shopName.EQ=${(shopName)}&createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}&page=${currentPage}`;
      } else {
        // eslint-disable-next-line max-len
        filterParams = `?status.EQ=${status}&shopTableName.EQ=${(tablenum)}&shopName.EQ=${(shopName)}&page=${currentPage}`;
      }
      setTimeout(() => {
        fetchFilterReservations({ sortParams, filterParams });
      }, 500);
    } else {
      fetchFilterReservations();
    }
  }, [location]);

  const onFromDateChange = (date) => {
    // eslint-disable-next-line no-underscore-dangle
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone).startOf('day');
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const tempDate = moment(utcDate).format(MOMENT_FORMAT);
    setFromDateWithTimezone(tempDate);
    setFromDate(Date);
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const status = parsed.status || '';
    const tablenum = (tableName || '');
    if (selectedShop === 'All') {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&fromDate=${tempDate}&toDate=${toDateWithTimeZone}&page=${currentPage}`,
      });
    } else if (toDate && toDate > tempDate) {
      setShowMessage(false);
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName.EQ=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectedShop.name || '')}&fromDate=${tempDate}&toDate=${toDateWithTimeZone}&page=${currentPage}`,
      });
    } else if (tempDate && toDate) {
      // setShowMessage(true);
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName.EQ=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectedShop.name || '')}&page=${currentPage}`,
      });
    }
  };

  const onToDateChange = (date) => {
    // eslint-disable-next-line no-underscore-dangle
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone).endOf('day');
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const tempDate = moment(utcDate).format(MOMENT_FORMAT);
    setToDateWithTimeZone(tempDate);
    setToDate(Date);
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const status = parsed.status || '';
    const tablenum = (tableName || '');
    if (selectedShop === 'All') {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&fromDate=${fromDateWithTimezone}&toDate=${tempDate}&page=${currentPage}`,
      });
    } else if (fromDate && fromDate < tempDate) {
      setShowMessage(false);
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName.EQ=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectedShop.name || '')}&fromDate=${fromDateWithTimezone}&toDate=${tempDate}&page=${currentPage}`,
      });
    } else {
      setShowMessage(true);
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName.EQ=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectedShop.name || '')}&page=${currentPage}`,
      });
    }
  };

  useEffect(() => {
    if (!validator) {
      setDeletePrinterLabelModal(false);
      const parsed = queryString.parse(location.search);
      const currentPage = parsed.page || 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const shopName = parsed.shopName || '';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      const dateStart = parsed.fromDate || '';
      const dateEnd = parsed.toDate || '';
      const status = parsed.status || '';
      const tablenum = parsed.shopTableName || '';
      // eslint-disable-next-line max-len
      const filterParams = `?status.EQ=${status}&shopTableName.EQ=${(tablenum)}&shopName.EQ=${(shopName)}&createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}&page=${currentPage}`;
      setTimeout(() => {
        fetchFilterReservations({ sortParams, filterParams });
      }, 500);
    }
  }, [validator]);

  const updateSelectedShop = (shop) => {
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    const status = parsed.status || '';
    const tablenum = (tableName || '');
    if (shop === 'All') {
      setSelectedShop('All');
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        search: `?status=${status}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,

      });
    } else {
      (setSelectedShop(shop[1]));
    }

    if (shop !== 'All') {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName.EQ=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(shop[1].name)}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    }
    setSelectedStatusType({});
    const payload = {
      shopGid: shop[0],
    };
    debouncedFetchDiningTable(payload);
    setFromDate('');
    setToDate('');
    setTableName('');
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryReservations]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const currentPage = page.current;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    const status = parsed.status || '';
    const tablenum = parsed.shopTableName || '';
    if (selectedShop === 'All' && !fromDat && !toDat) {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?&page=${currentPage}`,

      });
    } else if (selectedShop === 'All' && fromDat && toDat) {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectedShop.name || '')}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else
    if (fromDat && toDat && selectedShop !== 'All') {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectedShop.name || '')}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&page=${currentPage}&shopName=${encodeURIComponent(selectedShop.name || '')}`,
      });
    }
  };

  const updateSupport = (suportType) => {
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const tablenum = suportType;
    const status = selectedStatusType.value ? (selectedStatusType.value !== 'ALL' ? selectedStatusType.value : '') : '';
    const selectShop = (selectedShop.name) || '';
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    setTableName(suportType);
    if (fromDat && toDat) {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectShop)}&shopTableName=${encodeURIComponent(tablenum)}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectShop)}&shopTableName=${encodeURIComponent(tablenum)}&page=${currentPage}`,
      });
    }
  };

  const updateStatus = (suportType) => {
    const parsed = queryString.parse(location.search);
    const currentPage = 1;
    const status = suportType[0] !== 'ALL' ? suportType[0] : '';
    const fromDat = parsed.fromDate || '';
    const tablenum = (tableName) || '';
    const selectShop = (selectedShop.name) || '';
    const toDat = parsed.toDate || '';
    if (fromDat && toDat) {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectShop)}&shopTableName=${encodeURIComponent(tablenum)}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: PAGES.TABLE_RESERVATION,
        // eslint-disable-next-line max-len
        search: `?status=${status}&shopName=${encodeURIComponent(selectShop)}&shopTableName=${encodeURIComponent(tablenum)}&page=${currentPage}`,
      });
    }
    setSelectedStatusType(suportType[1]);
  };

  const handleReset = () => {
    const currentPage = 1;
    const status = selectedStatusType.value ? (selectedStatusType.value !== 'ALL' ? selectedStatusType.value : '') : '';
    const tablenum = (tableName) || '';
    const selectShop = (selectedShop.name) || '';
    setFromDate('');
    setToDate('');
    history.push({
      pathname: PAGES.TABLE_RESERVATION,
      // eslint-disable-next-line max-len
      search: `?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectShop)}&page=${currentPage}`,
    });
  };


  const selectedRecord = (record) => {
    const itemGid = record.gid;
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.page || 1;
    const fromDat = parsed.fromDate || '';
    const toDat = parsed.toDate || '';
    const status = selectedStatusType.value ? (selectedStatusType.value !== 'ALL' ? selectedStatusType.value : '') : '';
    const tablenum = (tableName) || '';
    const selectShop = (selectedShop.name) || '';
    // eslint-disable-next-line max-len
    fetchSearchReservations(`?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectShop)}&fromDate=${fromDat}&toDate=${toDat}&page=${currentPage}`);
    history.push({
      pathname: `${PAGES.TABLE_RESERVATION}/${itemGid}`,
      state: location.search,
    });
  };

  const selectedTableRecord = (record) => {
    const itemGid = record && record.shopTable && record.shopTable.gid;
    history.push({
      pathname: `${PAGES.DINNINGDETAILS}/${itemGid}`,
      state: location.search,
    });
  };

  const showDeletePrinterLabelModal = (value: Object) => {
    setSelectedPrinterLabel(value);
    setDeletePrinterLabelModal(true);
  };

  const deleteSelectedOrderType = (data) => {
    const {
      tableGid,
      note,
    } = data;
    const params = {
      status: 'CANCELED',
      note,
    };
    const status = selectedStatusType.value ? (selectedStatusType.value !== 'ALL' ? selectedStatusType.value : '') : '';
    const tablenum = (tableName) || '';
    const selectShop = (selectedShop.name) || '';
    const currentPage = 1;
    let shopSearch;
    if (fromDate && toDate) {
      // eslint-disable-next-line max-len
      shopSearch = `?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectShop)}&fromDate=${fromDate}&toDate=${toDate}&page=${currentPage}`;
    } else {
      shopSearch = `?status=${status}&shopTableName=${encodeURIComponent(tablenum)}&shopName=${encodeURIComponent(selectShop)}&page=${currentPage}`;
    }
    deleteInventoryReservations({
      gid: tableGid,
      params,
      shopSearch,
    });
  };

  const columns = [
    {
      title: 'SHOP',
      dataIndex: 'name',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => {
        const shopName = record && record.shopTable && record.shopTable.shop ? record.shopTable.shop && record.shopTable.shop.name : null;
        return (
          <span>
            {
              shopName || <>&#8211;</>
            }
          </span>
        );
      },
    },
    {
      title: 'TABLE',
      dataIndex: 'table',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (table, record) => {
        const tableNumber = record && record.shopTable ? record.shopTable && record.shopTable.name : null;
        return (
          <div
            onClick={(e) => { e.stopPropagation(); selectedTableRecord(record); }}
            style={{
              color: '#3E8BFF',
              textDecoration: 'underline',
            }}
          >
            {
              tableNumber || <>&#8211;</>
            }
          </div>
        );
      },
    },
    {
      title: 'GROUP ID',
      dataIndex: 'reservationCode',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (reservationCode) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {reservationCode || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'GUEST NAME',
      dataIndex: 'name',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.customer && record.customer.name) || (name || <>&#8211;</>)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'GUEST COUNT',
      dataIndex: 'noOfGuest',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (noOfGuest) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {noOfGuest || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>N/A</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <Tooltip placement="top" title="">
          <StatusTag style={{ backgroundColor: getReservationBackground(status) }}>
            {getReservation(status)}
          </StatusTag>
        </Tooltip>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    className: 'no-break-word',
    render: (text, record) => (
      <span>
        {
          (record && record.status === 'RESERVED') || (record && record.status === 'OCCUPIED') ? (
            <Tooltip placement="top" title="Delete">
              {/* eslint-disable-next-line */}
              <img
                className="ml-3"
                src={reservationCancel}
                alt="cancel"
                style={{ marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showDeletePrinterLabelModal(record); }}
              />
            </Tooltip>
          ) : <>&#8211;</>
        }
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 pb-2" align="middle">
        <Col>
          <Select
            className="mr-4 mt-3"
            value={selectedShop.name || 'All'}
            style={{ width: '350px' }}
            bordered
            onChange={(value) => updateSelectedShop(value)}
          >
            <Option key={1} value="All">All</Option>

            {
              shopsList.map((option) => (
                <Option
                  key={option.gid}
                  value={[option.gid, option]}
                >
                  {option.name}
                </Option>
              ))
            }
          </Select>
          {
            diningTable && diningTable.length !== 0 && (
              <Select
                className="mr-4 mt-3"
                value={tableName || 'Table No.'}
                style={{ width: 160 }}
                bordered
                showSearch
                onChange={(value) => updateSupport(value)}
              >
                {
                  diningTable.map((option) => (
                    <Option
                      key={option.gid}
                      value={option.name}
                    >
                      {option.name}
                    </Option>
                  ))
                }
              </Select>
            )
          }
          <Select
            className="mr-4 mt-3"
            value={selectedStatusType.name || 'Status'}
            style={{ width: 160 }}
            bordered
            showSearch
            onChange={(value) => updateStatus(value)}
          >
            {
              statusTypes.map((option) => (
                <Option
                  key={option.id}
                  value={[option.value, option]}
                >
                  {option.name}
                </Option>
              ))
            }
          </Select>
        </Col>
        {
          kdsRole !== 'KDS-USER' && (
            <Col>
              <Row type="flex" justify="space-between" align="middle">
                <Col className="mr-3">
                  <SpText color="rgba(0, 0, 0, 0.6)">From</SpText>
                  <DatePicker
                    value={fromDate ? moment(fromDate) : ''}
                    format={dateFormat}
                    allowClear={false}
                    onChange={onFromDateChange}
                    disabledDate={current => current && current > moment(disableDate)}
                  />
                </Col>
                <Col>
                  <SpText color="rgba(0, 0, 0, 0.6)">To</SpText>
                  <DatePicker
                    value={toDate ? moment(toDate) : ''}
                    format={dateFormat}
                    allowClear={false}
                    onChange={onToDateChange}
                    disabledDate={current => current && current > moment(disableDate)}
                  />
                </Col>
                <Col className="ml-3 mt-3">
                  <div
                    onClick={handleReset}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={Reset} alt="" />
                  </div>
                </Col>
              </Row>
              {
                showMessage && (
                  <SpError>Please select valid from to dates</SpError>
                )
              }
            </Col>
          )
        }
      </Row>
      <div style={{ border: '1px solid #959595', padding: '10px', borderRadius: '10px' }} className="mb-3">
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <SpText fontSize="22px" fontWeight="600" color="#292929">{selectedShop.name || 'All Shops'}</SpText>
          </Col>
          <Col>
            <SpText fontSize="16px" fontWeight="500" color="#292929">Date: {todayDate}</SpText>
          </Col>
        </Row>
      </div>
      {/* DELETE ORDER TYPE MODAL */}
      {deletePrinterLabelModal && (
        <DeletePrinterLabel
          visible={deletePrinterLabelModal}
          submitting={submitting}
          inventoryItemDetails={selectedPrinterLabel}
          selectedAccount={selectedAccount}
          close={() => setDeletePrinterLabelModal(false)}
          submit={deleteSelectedOrderType}
        />
      )}
      <RBAC
        fallback={(
          <Table
            columns={[...columns, actionColumn]}
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            rowKey={(record) => record.gid}
            dataSource={inventoryReservations}
            pagination={pagination}
            onChange={handleTableChange}
          />
        )}
        permit={
          [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
            ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
        }
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          columns={[...columns, actionColumn]}
          rowKey={(record) => record.gid}
          dataSource={inventoryReservations}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryReservations: state.inventoryReservations.content,
  totalElements: state.inventoryReservations.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  shops: state.customerSupport.shops,
  diningTable: state.dining.diningTable.content,
  preserveFilters: state.filterParams.preserveFilters,
  searchTypes: state.inventoryReservations.searchTypes,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryReservations: param => dispatch(inventoryReservationsActions.fetchInventoryReservations({
    payload: param,
  })),
  fetchFilterReservations: param => dispatch(inventoryReservationsActions.fetchFilterReservations({
    payload: param,
  })),
  fetchSearchReservations: param => dispatch(inventoryReservationsActions.fetchSearchReservations({
    payload: param,
  })),
  deleteInventoryReservations: param => dispatch(inventoryReservationsActions.deleteInventoryReservations({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchShops: param => dispatch(customerSupportActions.fetchShops({
    payload: param,
  })),
  fetchDiningTable: (param) => dispatch(diningActions.fetchDiningTable({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ReservationsList);
