/* eslint-disable */

export default (paymentMethod) => {
  if (paymentMethod) {
    const paymentType = paymentMethod.map(data =>
      (data === 'CREDIT_CARD_NOT_PRESENT' || data === 'DEBIT_CARD_NOT_PRESENT' || data === 'DEBIT_CARD_PRESENT' || data === 'CREDIT_CARD_PRESENT' || data === 'RUPAY_PRESENT' || data === 'RUPAY_NOT_PRESENT' || data === 'AMEX_NOT_PRESENT' || data === 'AMEX_PRESENT') 
      ? 'CARD' : (data === 'UPI_NOT_PRESENT' || data === 'UPI_PRESENT' || data === 'QR')
      ? 'UPI' : (data.includes('NET_BANKING')) ? 'NET_BANKING' : 
      (data.includes('GOOGLE_PAY_US') || data.includes('APPLE_PAY_US') || data.includes('SWIREPAY_US')) ? 'WALLET' : data
      );
    const uniquePaymentTypes = [...new Set(paymentType)];
    return uniquePaymentTypes;
  } else return ['CARD']
};