import {
  dashboard,
  customers,
  wallet,
  developers,
  report,
  settings,
  team,
  payments,
  terminal,
  paymentslink,
  admin,
  partner,
  transfers,
  paymentpages,
  orders,
  kdsdashboard,
  shops,
  PayLinks,
} from 'components/NavIcons';

import { ROLES } from 'appconstants';

const NAVBAR_OPTIONS = [
  {
    key: '/dashboard',
    icon: dashboard,
    display: 'Dashboard',
    width: 25,
    height: 22,
    // dropdown: [
    //   {
    //     key: '/customdashboard',
    //     display: 'Custom Dashbaord',
    //     allowedRoles: [
    //       ROLES.ADMINISTRATOR,
    //       ROLES.OWNER,
    //       ROLES.DEVELOPER,
    //       ROLES.OPERATIONS,
    //       ROLES.ANALYST,
    //       ROLES.SUPPORT,
    //       ROLES.INTERNALSUPPORT,
    //       ROLES.SUPERUSER,
    //       ROLES.ONBOARDING,
    //     ],
    //   },
    // ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.DEVICE,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/customdashboard',
    icon: dashboard,
    display: 'Custom Dashboard',
    width: 25,
    height: 22,
    allowedRoles: [
      ROLES.ANALYST,
    ],
  },
  {
    key: '/vieworders',
    icon: kdsdashboard,
    display: 'KDS Dashboard',
    width: 25,
    height: 22,
    allowedRoles: [
      ROLES.ASSOCIATE,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: '/payments',
    icon: payments,
    display: 'Payments',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/disputes',
        display: 'Disputes',
        id: 'balance',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/payouts',
        display: 'Payouts',
        id: 'balance',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/transaction',
        display: 'Transactions',
        id: 'balance',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          ROLES.SUPERUSER,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/accountsettlement',
        display: 'Settlement',
        id: 'accountsettlement',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          ROLES.SUPERUSER,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/transfercenter',
    icon: transfers,
    display: 'Transfer Center',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/transfers',
        display: 'View Activity',
        id: 'transfers',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/funding-sources/contacts',
        display: 'Contacts',
        id: 'funding-sources',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/sendmoney',
        display: 'Transfer Funds',
        id: 'sendmoney',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/vendorinvoice',
        display: 'Vendor Invoices',
        id: 'vendorinvoice',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      // {
      //   key: '/transfer-links',
      //   display: 'Transfer Links',
      //   id: 'transfer-links',
      //   allowedRoles: [
      //     ROLES.ADMINISTRATOR,
      //     ROLES.OWNER,
      //     ROLES.DEVELOPER,
      //     ROLES.OPERATIONS,
      //     // ROLES.ANALYST,
      //     ROLES.SUPPORT,
      //     ROLES.POS,
      //     ROLES.SUPERUSER,
      //   ],
      // },
      // {
      //   key: '/funding-sources',
      //   display: 'Funding Sources',
      //   id: 'funding-sources',
      //   allowedRoles: [
      //     ROLES.ADMINISTRATOR,
      //     ROLES.OWNER,
      //     ROLES.DEVELOPER,
      //     ROLES.OPERATIONS,
      //     // ROLES.ANALYST,
      //     ROLES.SUPPORT,
      //     ROLES.POS,
      //     ROLES.SUPERUSER,
      //   ],
      // },
      {
        key: '/bulk-transfer',
        display: 'Bulk Transfer',
        id: 'bulk-transfer',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      // {
      //   key: '/accounttransfers',
      //   display: 'Account Transfers',
      //   id: 'accounttransfers',
      //   allowedRoles: [
      //     ROLES.ADMINISTRATOR,
      //     ROLES.OWNER,
      //     ROLES.DEVELOPER,
      //     ROLES.OPERATIONS,
      //     // ROLES.ANALYST,
      //     ROLES.SUPPORT,
      //     ROLES.POS,
      //     ROLES.SUPERUSER,
      //   ],
      // },
      {
        key: '/manageaccounts',
        display: 'Manage Accounts',
        id: 'manageaccounts',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/pay-links',
    icon: PayLinks,
    display: 'Pay Links & Pages',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/payment-links',
        display: 'Links',
        id: 'payment-links',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/payment-buttons',
        display: 'Buttons',
        id: 'payment-buttons',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/subscription-button',
        display: 'Subscription',
        id: 'subscription-button',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/payment-widgets',
        display: 'Widgets',
        id: 'payment-widgets',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/payment-pages',
        display: 'Pages',
        id: 'payment-pages',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/checkout-page',
        display: 'Checkout',
        id: 'checkout-page',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/events',
        display: 'Seated Events',
        id: 'events',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/accountslug',
        display: 'Account Slug',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/reminders',
        display: 'Reminders',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  // {
  //   key: '/payment-links',
  //   icon: paymentslink,
  //   display: 'Payment Links',
  //   width: 25,
  //   height: 22,
  //   dropdown: [
  //     {
  //       key: '/subscription-button',
  //       display: 'Subscription Buttons',
  //       id: 'payment-links',
  //       allowedRoles: [
  //         ROLES.ADMINISTRATOR,
  //         ROLES.OWNER,
  //         ROLES.DEVELOPER,
  //         ROLES.OPERATIONS,
  //         // ROLES.ANALYST,
  //         ROLES.POS,
  //         ROLES.SUPERUSER,
  //       ],
  //     },
  //   ],
  //   allowedRoles: [
  //     ROLES.ADMINISTRATOR,
  //     ROLES.OWNER,
  //     ROLES.DEVELOPER,
  //     ROLES.OPERATIONS,
  //     // ROLES.ANALYST,
  //     ROLES.SUPPORT,
  //     ROLES.INTERNALSUPPORT,
  //     ROLES.POS,
  //     ROLES.SUPERUSER,
  //     ROLES.ONBOARDING,
  //   ],
  // },
  // {
  //   key: '/dining',
  //   icon: paymentslink,
  //   display: 'Dining',
  //   width: 25,
  //   height: 22,
  //   allowedRoles: [
  //     ROLES.ADMINISTRATOR,
  //     ROLES.OWNER,
  //     ROLES.DEVELOPER,
  //     ROLES.OPERATIONS,
  //     ROLES.ANALYST,
  //     ROLES.SUPPORT,
  //     ROLES.INTERNALSUPPORT,
  //     ROLES.POS,
  //     ROLES.SUPERUSER,
  //     ROLES.ONBOARDING,
  //   ],
  // },
  {
    key: '/inventory',
    icon: orders,
    display: 'Inventory',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/categories',
        display: 'Categories',
        id: 'categories',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/items',
        display: 'Items',
        id: 'items',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/menus',
        display: 'Menu',
        id: 'menus',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/modifiers',
        display: 'Modifier Groups',
        id: 'modifiers',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/ordertypes',
        display: 'Order Types',
        id: 'ordertypes',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/tax-rates',
        display: 'Tax Rates',
        id: 'taxrates',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/coupons',
        display: 'Coupons',
        id: 'coupons',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/offers',
        display: 'Offers',
        id: 'offers',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/printerlabel',
        display: 'Printer Labels',
        id: 'printerlabel',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/advancefee',
        display: 'Advancefee',
        id: 'advancefee',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: '/shops',
    icon: shops,
    display: 'Shop',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/orders',
        display: 'Orders',
        id: 'shops',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/reservations',
        display: 'Reservations',
        id: 'reservations',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/feedback',
        display: 'Feedback',
        id: 'feedback',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/preorder',
        display: 'Pre Orders',
        id: 'preorder',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/supportcustomer',
        display: 'Customer Support',
        id: 'customerSupport',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.KDSUSER,
          ROLES.KDSADMIN,
        ],
      },
      {
        key: '/service-rates',
        display: 'Service Charge',
        id: 'servicerates',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/operatinghours',
        display: 'Operating Hours',
        id: 'operatinghours',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: '/customer-solutions',
    icon: customers,
    display: 'Customer Solutions',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/customers',
        display: 'Customers',
        id: 'customers',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/invoices',
        display: 'Invoices',
        id: 'invoices',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/subscriptions',
        display: 'Subscriptions',
        id: 'subscriptions',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/reward',
        display: 'Reward',
        id: 'reward',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/terminals',
    icon: terminal,
    display: 'Terminals',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/locations',
        display: 'Locations',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/services',
        display: 'Service',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/productsterminals',
        display: 'Catalog',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/ocrrequest',
    icon: report,
    display: 'OCR Request',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/bankstatement',
        display: 'Bank Account Statement',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  // {
  //   key: '/wallet',
  //   icon: wallet,
  //   display: 'Wallet',
  //   width: 25,
  //   height: 22,
  //   dropdown: [
  //     {
  //       key: '/transactionsList',
  //       display: 'Transactions',
  //       id: 'transactionslist',
  //       allowedRoles: [
  //         ROLES.ADMINISTRATOR,
  //         ROLES.OWNER,
  //         ROLES.DEVELOPER,
  //         ROLES.OPERATIONS,
  //         // ROLES.ANALYST,
  //         ROLES.SUPPORT,
  //         ROLES.INTERNALSUPPORT,
  //         ROLES.POS,
  //         ROLES.SUPERUSER,
  //         ROLES.ONBOARDING,
  //       ],
  //     },
  //     {
  //       key: '/vendorinvoice-wallet',
  //       display: 'Vendor Invoice',
  //       id: 'vendorinvoice-wallet',
  //       allowedRoles: [
  //         ROLES.ADMINISTRATOR,
  //         ROLES.OWNER,
  //         ROLES.DEVELOPER,
  //         ROLES.OPERATIONS,
  //         // ROLES.ANALYST,
  //         ROLES.SUPPORT,
  //         ROLES.INTERNALSUPPORT,
  //         ROLES.POS,
  //         ROLES.SUPERUSER,
  //         ROLES.ONBOARDING,
  //       ],
  //     },
  //     {
  //       key: '/beneficiary',
  //       display: 'Beneficiary',
  //       id: 'beneficiary',
  //       allowedRoles: [
  //         ROLES.ADMINISTRATOR,
  //         ROLES.OWNER,
  //         ROLES.DEVELOPER,
  //         ROLES.OPERATIONS,
  //         // ROLES.ANALYST,
  //         ROLES.SUPPORT,
  //         ROLES.INTERNALSUPPORT,
  //         ROLES.POS,
  //         ROLES.SUPERUSER,
  //         ROLES.ONBOARDING,
  //       ],
  //     },
  //     {
  //       key: '/manage',
  //       display: 'Manage',
  //       id: 'manage',
  //       allowedRoles: [
  //         ROLES.ADMINISTRATOR,
  //         ROLES.OWNER,
  //         ROLES.DEVELOPER,
  //         ROLES.OPERATIONS,
  //         // ROLES.ANALYST,
  //         ROLES.SUPPORT,
  //         ROLES.INTERNALSUPPORT,
  //         ROLES.POS,
  //         ROLES.SUPERUSER,
  //         ROLES.ONBOARDING,
  //       ],
  //     },
  //   ],
  //   allowedRoles: [
  //     ROLES.ADMINISTRATOR,
  //     ROLES.OWNER,
  //     ROLES.DEVELOPER,
  //     ROLES.OPERATIONS,
  //     // ROLES.ANALYST,
  //     ROLES.SUPPORT,
  //     ROLES.INTERNALSUPPORT,
  //     ROLES.POS,
  //     ROLES.SUPERUSER,
  //     ROLES.ONBOARDING,
  //   ],
  // },
  {
    key: '/reports',
    icon: report,
    display: 'Reports',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/scheduled-report-attempt',
        display: 'Schedule Reports',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
      {
        key: '/customreport',
        display: 'Custom Report',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/developer',
    icon: developers,
    display: 'Developer',
    dropdown: [
      {
        key: '/webhooks',
        display: 'Webhooks',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/integrations',
        display: 'Integrations',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    width: 25,
    height: 22,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/admin',
    icon: admin,
    display: 'Admin',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/overview',
        display: 'Overview',
        id: 'overview',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/acquirer',
        display: 'Integrators',
        id: 'acquirer',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/catalog',
        display: 'Products',
        id: 'catalog',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/settlements',
        display: 'Settlements',
        id: 'settlements',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/bin-admin',
        display: 'Bin',
        id: 'bin',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/rate',
        display: 'Rates',
        id: 'rate',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/payment-admin',
        display: 'Payments',
        id: 'payment-admin',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/transfer-admin',
        display: 'Transfers',
        id: 'transfer-admin',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/terminal-admin',
        display: 'Terminals',
        id: 'terminals',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/staticqr-admin',
        display: 'Static QR',
        id: 'staticqr-admin',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/reporttemplates',
        display: 'Report Templates',
        id: 'reporttemplates',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/report-admin',
        display: 'Reports',
        id: 'report-admin',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/schedule-admin-reports',
        display: 'Schedule Reports',
        id: 'schedule-admin-reports',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/sp-object',
        display: 'SP Object',
        id: 'sp-field',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/sp-field',
        display: 'SP Field',
        id: 'sp-field',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/pos-keys',
        display: 'POS Key',
        id: 'pos-keys',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/global-restricted-key',
        display: 'GR Key',
        id: 'global-restricted-key',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/internaluser',
        display: 'Users',
        id: 'internaluser',
        allowedRoles: [
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/custom-dashboard',
        display: 'Dashboard',
        id: 'custom-dashboard',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
      {
        key: '/deals-slider',
        display: 'Deals slider',
        id: 'deals-slider',
        allowedRoles: [
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
          ROLES.INTERNALSUPPORT,
        ],
      },
    ],
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: '/settings',
    icon: settings,
    display: 'Settings',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/team',
        display: 'Team',
        width: 25,
        height: 22,
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '/partner',
    icon: partner,
    display: 'Partner',
    width: 25,
    height: 22,
    dropdown: [
      {
        key: '/transactions/partner',
        display: 'Transactions',
        id: 'transactions',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/earnings/partner',
        display: 'Earnings',
        id: 'earnings',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
      {
        key: '/settlement/partner',
        display: 'Settlement',
        id: 'settlement',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.POS,
          ROLES.SUPERUSER,
        ],
      },
    ],
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
].filter(route => !!route);

// eslint-disable-next-line array-callback-return,consistent-return
export default (role, recon, country) => NAVBAR_OPTIONS.filter(option => {
  if (recon === true) {
    if (option && country === true) {
      // eslint-disable-next-line no-param-reassign
      if (option.dropdown && option.dropdown.length === 0) { option.dropdown = undefined; }
      return option.allowedRoles.find(r => r === role) && (option && option.key !== '/wallet');
    }
    if (option && country === false) {
      // eslint-disable-next-line no-param-reassign
      option.dropdown = option.dropdown && option.dropdown.filter(value => value.allowedRoles && value.allowedRoles.find(r => r === role));
      // eslint-disable-next-line no-param-reassign
      if (option.dropdown && option.dropdown.length === 0) { option.dropdown = undefined; }
      return option.allowedRoles.find(r => r === role);
    }
  } else if (option && option.key !== '/ocrrequest' && country === true) {
    // eslint-disable-next-line no-param-reassign
    option.dropdown = option.dropdown && option.dropdown.filter(value => value.allowedRoles && value.allowedRoles.find(r => r === role));
    // eslint-disable-next-line no-param-reassign
    if (option.dropdown && option.dropdown.length === 0) { option.dropdown = undefined; }
    return option.allowedRoles.find(r => r === role) && (option && option.key !== '/wallet');
  } else if (option && option.key !== '/ocrrequest' && country === false) {
    // eslint-disable-next-line no-param-reassign
    option.dropdown = option.dropdown && option.dropdown.filter(value => value.allowedRoles && value.allowedRoles.find(r => r === role));
    // eslint-disable-next-line no-param-reassign
    if (option.dropdown && option.dropdown.length === 0) { option.dropdown = undefined; }
    return option.allowedRoles.find(r => r === role);
  } else if (country === undefined) {
    // eslint-disable-next-line no-param-reassign
    option.dropdown = option.dropdown && option.dropdown.filter(value => value.allowedRoles && value.allowedRoles.find(r => r === role));
    // eslint-disable-next-line no-param-reassign
    if (option.dropdown && option.dropdown.length === 0) { option.dropdown = undefined; }
    return option.allowedRoles.find(r => r === role);
  }
});
