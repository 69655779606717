import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  Menu,
  Icon,
  Card,
  Row,
  Col,
} from 'antd';
import queryString from 'query-string';

import {
  SpTab,
  SpText,
  SpButton,
} from 'components/DesignKit';
import {
  PAGES,
} from 'appconstants';
import {
  transferSettingsActions,
} from 'store/actions';
import debounce from 'lodash.debounce';
import transferIcon from 'components/NavIcons/transferIcon';
import arrow from 'imgs/arrow.png';
import AllTransfers from './components/AllTransfers';

type Props = {
  fetchTransferSetting: Function,
  enableLiveTransfer: Function,
  transferSettings: object,
  business: Object,
  validator: boolean,
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
    replace: Function,
  },
};

const Transfers = (props: Props) => {
  const { selectedAccount, test } = useSelector(state => state.account);
  const {
    location,
    history,
    fetchTransferSetting,
    transferSettings,
    enableLiveTransfer,
    business,
    validator,
  } = props;
  const [current, setCurrent] = useState('succeeded');
  const [redirect, setRedirect] = useState(false);
  const [enableText, setEnableText] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [accontNumber, setAccountNumber] = useState('****123');
  const { path } = useRouteMatch();
  const issuerType = selectedAccount && selectedAccount.issuerActive;

  const debouncedFetchTransferSettings = debounce(fetchTransferSetting, 2000, { leading: true });

  const getData = () => {
    debouncedFetchTransferSettings();
  };

  useEffect(() => {
    getData(location.search);
    if (business) {
      setAccountNumber(`************${business.bankAccountLastFour}`);
    }
  }, [location, test, selectedAccount]);

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.IN']) {
      const value = parsed['status.IN'];
      switch (value) {
        case 'SUCCEEDED': {
          setCurrent('succeeded');
          break;
        }
        case 'CANCELLED': {
          setCurrent('cancelled');
          break;
        }
        case 'DECLINED': {
          setCurrent('declined');
          break;
        }
        case 'TRANSFER_SCHEDULED': {
          setCurrent('scheduled');
          break;
        }
        case 'ALL_TRANSFERS': {
          setCurrent('transfers');
          break;
        }
        default:
          setCurrent('transfers');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.replace(`${path}?status.IN=SUCCEEDED`);
    }
  }, [location]);

  useEffect(() => {
    if (!validator && redirect) {
      setEnableText(true);
    }
  }, [validator]);

  const enableTransfer = async (event) => {
    event.preventDefault();
    enableLiveTransfer();
    setDisableButton(true);
    setRedirect(true);
  };

  return (
    <>
      {
        (!(transferSettings && Object.keys(transferSettings).length) && !test)
          ? (
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-50">
              <Icon
                component={transferIcon}
                className="mb-4"
                style={{ fontSize: '32px', textAlign: 'center' }}
              />
              <SpText
                fontSize="36px"
                color="#65668C"
              >
                Oops!
              </SpText>
              <SpText
                fontSize="20px"
                color="#65668C"
              >
                Please go to Settings and provide additional business
              </SpText>
              <SpText
                fontSize="20px"
                color="#65668C"
              >
                information to enable live transfers
              </SpText>
              <SpButton
                onClick={() => history.push(PAGES.TRANSFER_SETTINGS)}
                className="mt-4"
              >
                Configure Transfer <img className="ml-2" src={arrow} alt="arrow" />
              </SpButton>
            </div>
          )
          : (Boolean(transferSettings && Object.keys(transferSettings).length) && !test && !issuerType)
            ? (
              <Card className="mb-2">
                <Row type="flex" justify="space-between" className="my-2 mb-3">
                  <Col>
                    <SpText fontSize="12px" color="#999999" fontWeight="700">TRANSFER</SpText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SpButton
                      onClick={enableTransfer}
                      disabled={disableButton}
                    >
                      Enable Transfer
                    </SpButton>
                  </Col>
                </Row>
                {
                  enableText && (
                    <Row className="mt-4">
                      <Col>
                        <SpText
                          fontSize="14px"
                          fontWeight="400"
                          color="#65668C"
                        >
                          {`To re-verify your bank account we have sent microdeposits to your account number
                          ${accontNumber} provided on file. Our team (servicesupport@swirepay.com)
                          will reach out to you at
                          ${selectedAccount && selectedAccount.contactEmail}
                          to confirm these deposit amounts sent to your bank via email as well.`}
                        </SpText>
                      </Col>
                    </Row>
                  )
                }
              </Card>
            )
            : (
              <>
                <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                  <Menu.Item key="succeeded">
                    <SpTab
                      to={`${path}?status.IN=SUCCEEDED`}
                      selected={current === 'succeeded'}
                    >
                      Succeeded
                    </SpTab>
                  </Menu.Item>
                  <Menu.Item key="cancelled">
                    <SpTab
                      to={`${path}?status.IN=CANCELLED`}
                      selected={current === 'cancelled'}
                    >
                      Cancelled
                    </SpTab>
                  </Menu.Item>
                  <Menu.Item key="declined">
                    <SpTab
                      to={`${path}?status.IN=DECLINED`}
                      selected={current === 'declined'}
                    >
                      Declined
                    </SpTab>
                  </Menu.Item>
                  <Menu.Item key="scheduled">
                    <SpTab
                      to={`${path}?status.IN=TRANSFER_SCHEDULED`}
                      selected={current === 'scheduled'}
                    >
                      Scheduled
                    </SpTab>
                  </Menu.Item>
                  <Menu.Item key="transfers">
                    <SpTab
                      to={`${path}?status.IN=ALL_TRANSFERS`}
                      selected={current === 'transfers'}
                    >
                      All Transfers
                    </SpTab>
                  </Menu.Item>
                </Menu>
                <AllTransfers {...props} />
              </>
            )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  transferSettings: state.transferSettings.content,
  business: state.business.business,
  loading: state.loading.loading,
  totalElements: state.transferSettings.totalElements,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransferSetting: param => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: param,
  })),
  enableLiveTransfer: param => dispatch(transferSettingsActions.enableLiveTransfer({
    payload: param,
  })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Transfers));
